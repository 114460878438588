import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

import { MainWrapper, Title } from '../components/headings';

const Contact = ({ location }) => (
	<Layout location={location}>
		<MainWrapper color="#17C6E9">
			<Title>Contact</Title>
			{/* <Link to="/">This is a link</Link>
			 */}
			{/* <p>What ya got cooking?</p>
			<form
				name="contact"
				method="post"
				data-netlify="true"
				data-netlify-honeypot="bot-field"
			>
				<input type="hidden" name="bot-field" />

				<div className="field">
					<label htmlFor="message">Message</label>
					<textarea name="message" id="message" rows="6" />
				</div>
				<ul className="actions">
					<li>
						<input type="submit" value="Send Message" className="special" />
					</li>
					<li>
						<input type="reset" value="Clear" />
					</li>
				</ul>
			</form> */}
			<p>Feel free to contact me through the email below.</p>
			<p>
				<a href="mailto:bredehoft+ws@gmail.com" className="email">
					bredehoft+ws@gmail.com
				</a>
			</p>
		</MainWrapper>
	</Layout>
);

export default Contact;
