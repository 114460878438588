import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import TbLogo from '../images/svgs/travis_bredehoft_logo.svg';

import { SiteHeader, SiteNav, NavLink, Name } from './headings';

let LogoColor = '';

const setLogoColor = () => {
	const path = location.pathname;

	switch (path) {
	case '/about/':
		LogoColor = '#FFC647';
		break;
	case '/projects/':
		LogoColor = '#845fea';
		break;
	case '/contact/':
		LogoColor = '#17C6E9';
		break;

	default:
		LogoColor = '#FFFFFF';
		break;
	}

	// console.log(location.pathname);
	return LogoColor;
};

const Header = ({ siteTitle, logoColor }) => (
	<SiteHeader logoColor="white">
		<Link to="/">
			<TbLogo alt="Travis Bredehoft" className="tb-logo" />
			<Name>Travis Bredehoft</Name>
		</Link>
		<SiteNav>
			<ul>
				<li>
					<NavLink underline="#FFC647" to="/about">
						About
					</NavLink>
				</li>
				<li>
					<NavLink underline="#845fea" to="/projects">
						Projects
					</NavLink>
				</li>
				<li>
					<NavLink underline="#17C6E9" to="/contact">
						Contact
					</NavLink>
				</li>
			</ul>
		</SiteNav>
	</SiteHeader>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
	logoColor: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: '',
	logoColor: 'white',
};

export default Header;
