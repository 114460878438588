import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from './breakpoints';
import { SubHeader } from './headings';

import GithubIcon from '../images/svgs/github.svg';
import TwitterIcon from '../images/svgs/twitter.svg';
import LinkedInIcon from '../images/svgs/linkedin.svg';
import InstagramIcon from '../images/svgs/instagram.svg';

const SocialLinks = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	${media.tablet`
    display: flex;
    justify-content: center;
    li + li {
      margin-left: 20px;
    }
  `}
`;

const SocialLink = styled.a`
	border-radius: 3px;
	color: #fff;

	.social-icon {
		width: 48px;
		height: auto;
		fill: white;
		transition: all 350ms ease;
		will-change: transform;
		&:hover {
			fill: ${props => props.color};
			transform: scale(1.2);
			cursor: pointer;
		}
	}
`;

const FooterLinks = [
	{
		name: 'Twitter',
		link: 'https://twitter.com/_rzzo',
		color: '#4aa1ec',
		icon: <TwitterIcon className="social-icon" />,
	},
	{
		name: 'Github',
		link: 'https://github.com/rzzo',
		color: '#fff',
		icon: <GithubIcon className="social-icon" />,
	},
	{
		name: 'LinkedIn',
		link: 'https://www.linkedin.com/in/tbredehoft/',
		color: '#0077b5',
		icon: <LinkedInIcon className="social-icon" />,
	},
	{
		name: 'Instagram',
		link: 'https://www.instagram.com/rzzo/',
		color: '#c13584',
		icon: <InstagramIcon className="social-icon" />,
	},
];

export default class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<SubHeader>Where I am wading</SubHeader>
				<SocialLinks>
					{FooterLinks.map(link => (
						<li key={link.name}>
							<SocialLink
								target="_blank"
								rel="nofollow noopener noreferrer"
								color={link.color}
								href={link.link}
								alt={link.name}
								title={link.name}
							>
								{link.icon}
							</SocialLink>
						</li>
					))}
				</SocialLinks>
			</footer>
		);
	}
}
