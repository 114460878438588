import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
// import { Spring } from 'react-spring';

import 'typeface-arimo';
import 'typeface-lato';
import Header from './header';
import { Main } from './headings';
import './layout.css';
import Footer from './footer';

// import Archive from './archive';
// import styled from 'styled-components';

// const MainLayout = styled.main`
// 	max-width: 90%;
// 	margin 1rem auto;
// 	display: grid;
// 	grid-template-columns: 3fr 1fr;
// 	grid-gap: 40px;
// `;

const Layout = ({ children, location, color }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={data => (
			<>
				<Header siteTitle={data.site.siteMetadata.title} logoColor={'green'} />
				{/* <Spring
          {...console.log('layout', location.pathname)}
          from={{ height: location.pathname === '/' ? 100 : 200 }}
          to={{ height: location.pathname === '/' ? 200 : 100 }}
        >
          {styles => (
            <div style={{ overflow: 'hidden', ...styles }}>
              <Img fluid={data.file.childImageSharp.fluid} />
            </div>
          )}
        </Spring> */}

				<Main>
					{children}
					{/* <Archive /> */}

					{/* <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer> */}
				</Main>
				<Footer />
			</>
		)}
	/>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
	location: {},
};

export default Layout;
